<template>
  <modal name="requestLogged" transition="pop-out">
    <div class="thanksPopup modal requestLogged">
      <div class="thanksPopup__inner">
        <div class="thanksPopup__title">Request logged</div>
        <div class="thanksPopup__text">We have received your Empty Leg request and
          will be back to you ASAP within two business
          days if we can fulfill a request for the opposite
          route, thereby helping to save both parties 40%
          on the trip.
        </div>
        <div style="display: flex;">
          <div
            style="margin-right: 10px; width: 212px"
            class="thanksPopup__button thanksPopup__button--no js-yes-btn bidRegisteredPopup"
            @click="this.hide"
          >
            <span class="text" style="letter-spacing: 0">Search results</span>
          </div>
          <div
            style="width: 212px"
            class="thanksPopup__button thanksPopup__button--yes js-yes-btn bidRegisteredPopup"
            @click="this.toRequests"
          >
            <span class="text" style="letter-spacing: 0">View requests</span>
          </div>
        </div>
      </div>
    </div>
    <button type="button" class="modal__close" @click="this.hide">
      <SvgIcon name="close-white"/>
    </button>
  </modal>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import './RequestLoggedPopup.styl'

export default {
  name: 'RequestLoggedPopup',
  methods: {
    show () {
      this.$modal.show('requestLogged')
    },
    hide () {
      this.$modal.hide('publishBid')
      this.$modal.hide('requestLogged')
    },
    toRequests () {
      this.$modal.hide('publishBid')
      this.$router.push('/dashboard/requests');
    },
  },
  created () {
    this.$parent.$on('showModal', this.show)
  },
  components: {
    SvgIcon,
  },
}
</script>
