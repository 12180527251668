<template>
  <modal name="BookRoutesPopup" transition="pop-out" @opened="opened" :adaptive="true">
    <div class="bookRoutesPopup" id="bookRoutesPopup">
      <div class="bookRoutesPopup__inner">
        <div class="bookRoutesPopup__top">
          <div class="bookRoutesPopup__top-photo"
               style="background-image:url('https://res.cloudinary.com/dyyrf9gqp/image/upload/v1600460709/FlyJets/1_cftcns.jpg')"></div>
          <div class="bookRoutesPopup__top-locations">
            <div class="bookRoutesPopup__top-locationsLine"
                 :style="{backgroundImage: `url(${line})`}"></div>
            <div class="bookRoutesPopup__top-locationElem">
              <div class="bookRoutesPopup__top-locationElem-title">New York-Area</div>
              <div class="bookRoutesPopup__top-locationElem-time">4:30 pm - 6:30 pm apr 24, jfk</div>
            </div>
            <div class="bookRoutesPopup__top-locationElem">
              <div class="bookRoutesPopup__top-locationElem-title">Orlando international airport (MCO)</div>
              <div class="bookRoutesPopup__top-locationElem-time">4:30 pm - 6:30 pm apr 24, jfk</div>
            </div>
          </div>
        </div>
        <div class="bookRoutesPopup__descr bookRoutesPopup__descr--pax">
          <div class="bookRoutesPopup__descr-elem bookRoutesPopup__descr-elem--big">
            <div class="bookRoutesPopup__descr-elemTitle bookRoutesPopup__descr--bold">Price</div>
            <div class="bookRoutesPopup__descr-elemValue bookRoutesPopup__descr--bold bookRoutesPopup__descr--green">
              $20,000
            </div>
          </div>
          <div class="bookRoutesPopup__descr-elem bookRoutesPopup__descr-elem--big">
            <div class="bookRoutesPopup__descr-elemTitle bookRoutesPopup__descr--bold">Trip Time</div>
            <div class="bookRoutesPopup__descr-elemValue bookRoutesPopup__descr--bold bookRoutesPopup__descr--green">2h
              20 Min
            </div>
          </div>
          <div class="bookRoutesPopup__descr-elem">
            <div class="bookRoutesPopup__descr-elemTitle">Max Pax</div>
            <div class="bookRoutesPopup__descr-elemValue">14</div>
          </div>
          <div class="bookRoutesPopup__descr-elem">
            <div class="bookRoutesPopup__descr-elemTitle">SAFETY</div>
            <div class="bookRoutesPopup__descr-elemValue">Argus Platinum; Wywern Wingman</div>
          </div>
          <div class="bookRoutesPopup__descr-elem">
            <div class="bookRoutesPopup__descr-elemTitle">Model</div>
            <div class="bookRoutesPopup__descr-elemValue"><a href="#">Gulfstream g500</a></div>
          </div>
        </div>
        <div class="bookRoutesPopup__descr bookRoutesPopup__descr--aircraft">
          <div class="bookRoutesPopup__descr-elem bookRoutesPopup__descr-elem--aircraft">
            <div class="bookRoutesPopup__descr-elemTitle">Category:</div>
            <div class="bookRoutesPopup__descr-elemValue bookRoutesPopup__descr--bold">Heavy</div>
          </div>
          <div class="bookRoutesPopup__descr-elem bookRoutesPopup__descr-elem--aircraft">
            <div class="bookRoutesPopup__descr-elemTitle">Range:</div>
            <div class="bookRoutesPopup__descr-elemValue bookRoutesPopup__descr--bold">5,200 nm</div>
          </div>
          <div class="bookRoutesPopup__descr-elem bookRoutesPopup__descr-elem--aircraft">
            <div class="bookRoutesPopup__descr-elemTitle">Speed:</div>
            <div class="bookRoutesPopup__descr-elemValue bookRoutesPopup__descr--bold">650 mph</div>
          </div>
          <div class="bookRoutesPopup__descr-elem bookRoutesPopup__descr-elem--aircraft">
            <div class="bookRoutesPopup__descr-elemTitle">Payload (max fuel):</div>
            <div class="bookRoutesPopup__descr-elemValue bookRoutesPopup__descr--bold">1,800 lb</div>
          </div>
        </div>
        <div class="bookRoutesPopup__bottom">
          <div class="bookRoutesPopup__bottom-text">
            <div class="bookRoutesPopup__bottom-title">seat number: row 4 seat b</div>
            <a class="bookRoutesPopup__bottom-change" href="#">Change</a></div>
          <div class="bookRoutesPopup__bottom-buttonWrap">
            <span
              class="bookRoutesPopup__bottom-button js-popup"
              @click="showBooking">
              <span class="bookRoutesPopup__bottom-textButton">Book</span>
            </span>
          </div>
        </div>
      </div>
      <button class="fancybox-button fancybox-close-small" @click="$modal.hide('BookRoutesPopup')"></button>
    </div>
  </modal>
</template>

<script>
  import line from '@/assets/img/map/line.png'

  export default {
    name: 'BookRoutesPopup',
    data: () => ({
      line: line,
    }),
    methods: {
      opened () {
        if (document.querySelector('html').classList.contains('d-mobile')) {
          const container = document.querySelector('.vm--container')
          const popup = document.querySelector('.bookRoutesPopup')

          container.classList.add('js-bookRoutesPopup')
          document.querySelector('body').appendChild(container)
          setTimeout(() => {
            popup.style.opacity = 1
          }, 300)
        }
      },
      showBooking () {
        this.$modal.hide('BookRoutesPopup')
        this.$modal.show('bookConfirmPopup')
      },
    },
  }
</script>
