<template>
  <div class="plane-loading-wrapper" v-if="false">
    <div class="icon-wrapper">
        <SvgIcon name="plane-loading" />
    </div>
  </div>
</template>

<script>

import SvgIcon from '@/components/common/SvgIcon/SvgIcon.vue';
import './LoadingPlane.styl';

export default {
  name: 'LoadingPlane',
  props: ['isLoading'],
  components: {
    SvgIcon,
  },
}
</script>
