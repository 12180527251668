<template>
  <vue-scroll :ops="ops" class="mapBlock__results-list js-customScroll">
    <div
      class="mapBlock__results-list-item js-sidebarListItem"
      data-href="#bookRoutesPopup"
      @mouseenter="openContent"
      @mouseleave="closeContent"
      v-for="(item, index) of list"
      :key="index"
      @click="showMobilePopup"
    >
      <div class="mapBlock__results-list-item-inner">
        <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--photo">
          <div
            class="mapBlock__results-list-item-img"
            :style="{backgroundImage: `url(${item.defaultImageUrl})`}"
            @click="showAircraftSearchGallery(item)"
          >
            <span class="mapBlock__results-list-item-img-back">
              <SvgIcon name="zoom"/>
            </span>
          </div>
        </div>
        <div class="mapBlock__results-list-item-column mapBlock__results-list-item-column--locations">
          <ul class="mapBlock__results-list-item-route">
            <li class="mapBlock__results-list-item-route-locationOne">
              <div class="mapBlock__results-list-item-title mapBlock__results-list-item-title--hideForMobile">Out</div>
              <div class="mapBlock__results-list-item-text">{{item.departure}}</div>
            </li>
            <li class="mapBlock__results-list-item-route-arrow">
              <div class="mapBlock__results-list-item-arrow">
                <SvgIcon name="long-left-arrow"/>
              </div>
            </li>
<!--            <li class="mapBlock__results-list-item-route-dateForMobile">4:30 pm - 6:30 pm apr 24, jfk</li>-->
            <li class="mapBlock__results-list-item-route-locationTwo">
              <div class="mapBlock__results-list-item-title mapBlock__results-list-item-title--hideForMobile">In</div>
              <div class="mapBlock__results-list-item-text">{{item.arrival}}</div>
            </li>
<!--            <li class="mapBlock__results-list-item-route-dateForMobile">4:30 pm - 6:30 pm apr 24, jfk</li>-->
          </ul>
<!--          <div class="mapBlock__results-list-item-date">-->
<!--            <div class="mapBlock__results-list-item-title">Dates</div>-->
<!--            <div class="mapBlock__results-list-item-text">{{item.dates}}</div>-->
<!--            <ul class="mapBlock__results-tooltip">-->
<!--              <li>-->
<!--                <div class="mapBlock__results-tooltip-title">Outbound/leg one:</div>-->
<!--                <div class="mapBlock__results-tooltip-date">-->
<!--                  <span class="mapBlock__results-tooltip-subtitle">Date:</span>-->
<!--                  <span class="mapBlock__results-tooltip-value">{{item.dates}}</span>-->
<!--                </div>-->
<!--                <div class="mapBlock__results-tooltip-time">-->
<!--                  <span class="mapBlock__results-tooltip-subtitle">Time:</span>-->
<!--                  <span-->
<!--                    class="mapBlock__results-tooltip-link js-requestPopup"-->
<!--                    @click="$modal.show('timeRequestPopup')"-->
<!--                    data-mod="white-popup out-close">-->
<!--                    click to request-->
<!--                  </span>-->
<!--                </div>-->
<!--              </li>-->
<!--              <li>-->
<!--                <div class="mapBlock__results-tooltip-title">Inbound/leg two:</div>-->
<!--                <div class="mapBlock__results-tooltip-date">-->
<!--                  <span class="mapBlock__results-tooltip-subtitle">Date:</span>-->
<!--                  <span class="mapBlock__results-tooltip-value">{{item.dates}}</span>-->
<!--                </div>-->
<!--                <div class="mapBlock__results-tooltip-time">-->
<!--                  <span class="mapBlock__results-tooltip-subtitle">Time:</span>-->
<!--                  <span-->
<!--                    class="mapBlock__results-tooltip-link js-requestPopup"-->
<!--                    @click="$modal.show('timeRequestPopup')"-->
<!--                    data-mod="white-popup out-close">-->
<!--                    click to request-->
<!--                  </span>-->
<!--                </div>-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--aircraftModel"
        >
          <div class="mapBlock__results-list-item-title">Aircraft model</div>
          <div class="mapBlock__results-list-item-text">
            {{ item.aircraftModel }}
          </div>
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--aircraftType"
        >
          <div class="mapBlock__results-list-item-title">Aicraft type</div>
          <div class="mapBlock__results-list-item-text">
            {{ item.aircraftType }}
          </div>
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--safety"
        >
          <div class="mapBlock__results-list-item-title">Safety rating</div>
          <div class="mapBlock__results-list-item-text">
            {{ item.aircraftArgusSafetyRating }}
          </div>
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--fuelStops"
        >
          <div class="mapBlock__results-list-item-title">
            Base<br v-if="!dMobile" /><br v-if="!dMobile" />
          </div>
          <div class="mapBlock__results-list-item-text">
            {{ item.homeBase }}
          </div>
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--pax"
        >
          <div class="mapBlock__results-list-item-title">Max pax</div>
          <div class="mapBlock__results-list-item-text">
            {{ item.aircraftPax }}
          </div>
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--tripTime"
        >
          <div class="mapBlock__results-list-item-title">
            Estimated trip time
          </div>
          <div class="mapBlock__results-list-item-text">
            {{ item.flightDurationHours }}h {{ item.flightDurationMinutes }}min
          </div>
          <div class="mapBlock__results-list-item-textTimeForMobile">
            {{ item.flightDurationHours }}h {{ item.flightDurationMinutes }}min
          </div>
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--price"
        >
          <div class="mapBlock__results-list-item-title">Estimated price</div>
          <div v-if="type === 'book'" class="mapBlock__results-list-item-text">
            {{
              item.exclusiveTotalPrice ? item.exclusiveTotalPrice.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) : ''
            }}
          </div>
          <div v-else class="mapBlock__results-list-item-text">
            {{
              item.exclusiveTotalPrice ? item.exclusiveTotalPrice.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              }) : ''
            }}
          </div>
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--buttons form form--filterSearch js-filterSearch"
          :class="[{ loading: loading }]"
          v-if="type && type === 'reserve'"
        >
          <Spinner v-if="id === index" />
          <button
            class="mapBlock__results-list-item-button mapBlock__results-list-item-button--green"
            @click="createBooking(item.aircraftAvailabilityId, index)"
            :disabled="id === index && loading"
          >
            <span class="text">Reserve</span>
          </button>
          <!--          <router-link class="mapBlock__results-list-item-link" to="#">View seat chart for prices</router-link>-->
        </div>
        <div
          class="mapBlock__results-list-item-column mapBlock__results-list-item-column--buttons form form--filterSearch js-filterSearch"
          :class="[{ loading: loading }]"
          v-else
        >
          <div v-if="($store.getters.getNameActiveSubscriptionPlanName && item.currentBidValue) || isFlights || item.isFlyEmpty" class="currentBid--text">
            <div class="mapBlock__results-list-item-text" v-if="$store.getters.getNameActiveSubscriptionPlanName && item.currentBidValue">
                Current BID:  {{
                  item.currentBidValue.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })
                }}
            </div>
            <div class="mapBlock__results-list-item-text" style="margin-right: 2px" v-if="isFlights">
                {{ item.isFlyEmpty ? 'FLY Empty' : 'Empty Leg'}}
            </div>
            <div class="mapBlock__results-list-item-text" style="margin-right: 2px" v-else-if="item.isFlyEmpty">
              Empty Requested
            </div>
          </div>
          <Spinner v-if="id === index" />
          <div v-if="$store.getters.getNameActiveSubscriptionPlanName && !isFlights" class="bookButton-wrapper">
            <button
              class="mapBlock__results-list-item-button mapBlock__results-list-item-button--green js-popup form__button"
              @click="openBidRequestModal(item, index)"
              data-mod="white-popup out-close"
              :disabled="id === index && loading"
            >
              <span style="font-size: 13px" class="text">GO</span>
            </button>
          </div>
          <div v-else class="bookButton-wrapper">
            <button
              class="mapBlock__results-list-item-button mapBlock__results-list-item-button--green js-popup form__button"
              @click="createBooking(item.aircraftAvailabilityId, index)"
              data-mod="white-popup out-close"
              :disabled="id === index && loading"
            >
              <span class="text">Book</span>
            </button>
          </div>
        </div>
      </div>
      <div class="mapBlock__results-list-item-detail js-sidebarListItemDetail">
        <div class="mapBlock__results-list-item-detail-inner">
          <div class="mapBlock__results-list-item-detail-column">
            <div class="mapBlock__results-list-item-detail-title">Speed:</div>
            <div class="mapBlock__results-list-item-detail-text">
              {{ item.aircraftSpeed }} MPH
            </div>
          </div>
          <div class="mapBlock__results-list-item-detail-column">
            <div class="mapBlock__results-list-item-detail-title">Range:</div>
            <div class="mapBlock__results-list-item-detail-text">
              {{ item.aircraftRange }} NM
            </div>
          </div>
          <!--          <div class="mapBlock__results-list-item-detail-column">-->
          <!--            <div class="mapBlock__results-list-item-detail-title">Payload (max fuel):</div>-->
          <!--            <div class="mapBlock__results-list-item-detail-text">1,800 LB</div>-->
          <!--          </div>-->
          <div class="mapBlock__results-list-item-detail-column">
            <div class="mapBlock__results-list-item-detail-title">
              Wi-fi onboard:
            </div>
            <div class="mapBlock__results-list-item-detail-text">
              {{ item.wiFi === true ? "Yes" : "No" }}
            </div>
          </div>
          <div class="mapBlock__results-list-item-detail-column">
            <div class="mapBlock__results-list-item-detail-title">YOM:</div>
            <div class="mapBlock__results-list-item-detail-text">
              {{ item.manufactureYear ? item.manufactureYear : "N/A" }}
            </div>
          </div>
          <div class="mapBlock__results-list-item-detail-column">
            <div class="mapBlock__results-list-item-detail-title">
              Refurbish:
            </div>
            <div class="mapBlock__results-list-item-detail-text">
              {{ item.intRefurbishYear ? item.intRefurbishYear : "N/A" }} (INT);
              {{ item.extRefurbishYear ? item.extRefurbishYear : "N/A" }} (EXT)
            </div>
          </div>
          <!--          <div v-if="item.departureRerouteTimeHours != 0 || item.departureRerouteTimeMinutes != 0" class="mapBlock__results-list-item-detail-column">-->
          <!--            <div class="mapBlock__results-list-item-detail-title">Reroute</div>-->
          <!--            <div class="mapBlock__results-list-item-detail-text">{{(item.departureRerouteTimeHours != 0 ? item.departureRerouteTimeHours + "H " : " ") + (item.departureRerouteTimeMinutes != 0 ? item.departureRerouteTimeMinutes + "MIN" : "")}}</div>-->
          <!--          </div>-->
          <div
            v-if="item.bookableDemo === true"
            class="mapBlock__results-list-item-detail-column mapBlock__results-list-item-detail-column--status"
          >
            <div class="mapBlock__results-list-item-detail-icon">
              <SvgIcon name="checked" />
            </div>
            <div class="mapBlock__results-list-item-detail-title">
              Bookable Demo
            </div>
          </div>
          <div
            v-if="item.isFloatingFleet === true"
            class="mapBlock__results-list-item-detail-column mapBlock__results-list-item-detail-column--status"
          >
            <div class="mapBlock__results-list-item-detail-icon">
              <SvgIcon name="checked" />
            </div>
            <div class="mapBlock__results-list-item-detail-title">
              Floating Fleet
            </div>
          </div>
        </div>
      </div>
    </div>
    <BidRequestEmptyPopup
      :previousBid="activeTrip.currentBidValue"
      :basePrice="activeTrip.exclusiveTotalPrice"
      @publishBid="publishBid"
      @confirmRequest="confirmRequest"
      @createBooking="createBooking"
      :types="{'bid': !activeTrip.bidUnavailabilityReason, 'request': !activeTrip.requestEmptyUnavailabilityReason, book: true}"
      :isLoading="isLoading"
      :reasons="{'bid': activeTrip.bidUnavailabilityReason, 'request': activeTrip.requestEmptyUnavailabilityReason}"
    />
    <BidRegisteredPopup />
    <RequestLoggedPopup />
  </vue-scroll>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
import Spinner from '@/components/common/Spinner/Spinner';
import BidRequestEmptyPopup from '@/components/common/modals/BidRequestEmptyPopup.vue';
import RequestLoggedPopup from '@/components/common/modals/RequestLoggedPopup.vue';
import BidRegisteredPopup from '@/components/common/modals/BidRegisteredPopup.vue';

export default {
  props: ['type', 'list', 'isFlights'],
  name: 'ResultsList',
  data: () => ({
    ops: {
      rail: {
        size: '3px',
        gutterOfEnds: '3px',
        gutterOfSide: '3px',
      },
      bar: {
        size: '3px',
        background: 'rgba(0,0,0,0.1)',
      },
    },
    loading: false,
    id: null,
    dMobile: undefined,
    activeTrip: {},
    isLoading: false,
  }),
  mounted () {
    this.dMobile = document
      .querySelector('html')
      .classList.contains('d-mobile');
  },
  methods: {
    openContent (event) {
      if (!document.querySelector('html').classList.contains('d-mobile')) {
        const item = event.currentTarget.querySelector(
          '.js-sidebarListItemDetail',
        );

        this.$root.animations.slideDown(item);
      }
    },
    closeContent (event) {
      if (!document.querySelector('html').classList.contains('d-mobile')) {
        const item = event.currentTarget.querySelector(
          '.js-sidebarListItemDetail',
        );
        this.$root.animations.slideUp(item);
      }
    },
    showMobilePopup (event) {
      if (document.querySelector('html').classList.contains('d-mobile')) {
        const item = event.currentTarget.querySelector(
          '.js-sidebarListItemDetail',
        );
        if (item.style.display === '') {
          item.style = 'display: block;';
        } else {
          item.style = '';
        }
      }
    },
    async showAircraftSearchGallery (item) {
      // send request
      await this.$store.dispatch('setAircraftSearchGallery', item.aircraftId);
      this.$modal.show('AircraftSearchPopup');
    },
    async createBooking (id, index) {
      this.isLoading = true;

      let all = [
        ...this.$store.state.search.searchResults.aircraftAvailabilities,
        ...this.$store.state.search.searchResults.emptyLegs,
      ];
      if (this.type === 'reserve') {
        all = [
          ...this.$store.state.search.searchResultsCharterFlights
            .aircraftAvailabilities,
          ...this.$store.state.search.searchResultsCharterFlights.emptyLegs,
        ];
      }
      this.$store.commit('clearTravelers');

      // TODO: fix booking bug with spaces
      // console.log(all)

      const data = all.find((item) => item.aircraftAvailabilityId === (id ||
       this.activeTrip.aircraftAvailabilityId));

      if (data) {
        this.loading = true;
        this.id = index === undefined ? this.index : index;

        const result = await this.$store.dispatch('createBooking', data);

        if (result) {
          this.$router.push('booking/flights');
          setTimeout(() => {
            this.loading = false;
            this.id = null;
            this.isLoading = false;
          }, 5000);
        }
      } else {
        this.isLoading = false;
        console.log(
          'Something has gone wrong when attempting to create a booking/reservation.',
        );
      }
    },
    async publishBid (bidValue, splitWithGroup = false) {
      this.isLoading = true
      const {departureDate, aircraftAvailabilityId, departureId, arrivalId, exclusiveTotalPrice, flightId} = this.activeTrip

      let res = null;

      if (flightId) {
        const data = {
          flightId,
          bidValue,
          exclusiveTotalPrice,
          willingToSplitWithGroup: splitWithGroup,
        };
        res = await this.$store.dispatch('updateBid', data);
      } else {
        const data = {
          flightDepartureDate: departureDate,
          aircraftAvailabilityId,
          departureId,
          arrivalId,
          bidValue: +bidValue,
          exclusiveTotalPrice,
          willingToSplitWithGroup: splitWithGroup,
        };
        res = await this.$store.dispatch('addNewBid', data);
      }

      if (res && res.data.isSuccessfull) {
        this.$store.commit('setCurrentBidValue', {
          aircraftAvailabilityId,
          currentBidValue: +bidValue,
        });

        this.isLoading = false

        this.$modal.show('bidRegistered');
      } else {
        if (res) {
          this.bidUnavailabilityReason = res.data.message
          console.log(res.data.message)
        }

        this.showInfoDialog('Something went wrong');
      }
    },
    async confirmRequest () {
      this.isLoading = true

      const {departureDate, aircraftAvailabilityId, departureId, arrivalId, exclusiveTotalPrice} = this.activeTrip

      const data = {
        flightDate: departureDate,
        aircraftAvailabilityId,
        departureId,
        arrivalId,
        exclusiveTotalPrice,
      };

      const res = await this.$store.dispatch('confirmRequestEmpty', data);
      this.isLoading = false;

      if (res && res.data.isSuccessfull) {
        this.$store.commit('setIsFlyEmpty', {
          aircraftAvailabilityId,
          isFlyEmpty: true,
        });
        this.$modal.show('requestLogged');
      } else {
        if (res) {
          this.activeTrip.requestEmptyUnavailabilityReason = res.data.message
          console.log(res.data.message);
        }
        const message = {
          title: 'Error',
          text: 'Something went wrong',
        }
        this.$modal.show('ThanksPopup', {message})
      }
    },
    showInfoDialog (title) {
      this.$modal.show('InfoPopup', { title: title });
    },
    openBidRequestModal (activeTrip, index) {
      this.activeTrip = activeTrip;
      this.$modal.show('publishBid');
      this.index = index
    },
  },
  components: {
    BidRequestEmptyPopup,
    BidRegisteredPopup,
    RequestLoggedPopup,
    SvgIcon,
    Spinner,
  },
};
</script>
