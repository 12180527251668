<template>
  <div>
    <modal name="bidRegistered" transition="pop-out">
      <div class="thanksPopup modal bidRegistered">
        <div class="spinnerWrapper" v-if="isClicked">
          <Spinner/>
        </div>
        <div class="thanksPopup__inner" :class="{'loading': isClicked}">
          <div class="thanksPopup__title">Success</div>
          <div class="thanksPopup__text" style="text-align: center">Bid registered. We will let you as soon as possible know if the Aircraft Provider accepts your offer.</div>
          <div style="display: flex;">
            <div
            style="margin-right: 10px"
            class="thanksPopup__button thanksPopup__button--no js-yes-btn bidRegisteredPopup"
            @click="this.hide"
            >
              <span class="text" style="letter-spacing: 0">Search results</span>
            </div>
            <div
              style="width: 212px"
              class="thanksPopup__button thanksPopup__button--yes js-yes-btn bidRegisteredPopup"
              @click="this.toAuctions"
            >
              <span class="text" style="letter-spacing: 0">View bids</span>
            </div>
          </div>
        </div>
      </div>
      <button type="button" class="modal__close" @click="this.hide">
        <SvgIcon name="close-white"/>
      </button>
    </modal>
    <LoadingPlane :isLoading="isLoading"/>
  </div>
</template>

<script>
import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
import LoadingPlane from '@/components/common/LoadingPlane/LoadingPlane';
import Spinner from '@/components/common/Spinner/Spinner.vue';

export default {
  name: 'BidRegisteredPopup',
  data: () => ({
    isLoading: false,
    isClicked: false,
  }),
  methods: {
    show () {
      this.$modal.show('bidRegistered')
    },
    toAuctions () {
      this.isClicked = true
      this.$modal.hide('publishBid')
      this.$modal.hide('bidRegistered')
      this.isClicked = false
      this.isLoading = true
      this.$router.push('/dashboard/auctions')
    },
    hide () {
      this.isClicked = true
      this.$modal.hide('publishBid')
      this.$modal.hide('bidRegistered')
      this.isClicked = false
    },
  },
  created () {
    this.$parent.$on('showModal', this.show)
  },
  components: {
    Spinner,
    SvgIcon,
    LoadingPlane,
  },
}
</script>
