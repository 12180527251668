<template>
  <div class="box box--mapBlock">
    <div class="box__inner">
      <div class="mapBlock js-tabBox mapBlock--results mapBlock--search">
        <div
          class="mapBlock__header"
          style="display: flex;"
        >
          <div class="mapBlock__header-top">
            <div class="mapBlock__header-inner">
              <router-link class="mapBlock__back" to="map-search">
                <span class="icon">
                  <SvgIcon name="left-arrow"/>
                </span>
                <span class="text">Back to map</span>
              </router-link>
              <div class="mapBlock__info">
                <div class="mapBlock__info-left">
                  <div class="mapBlock__info-title">Search:</div>
                  <router-link class="mapBlock__info-link" to="map-search">edit search</router-link>
                </div>
                <div class="mapBlock__info-right">
                  <div class="mapBlock__info-list">
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--route" style="align-items: center;">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="placeholder"/>
                      </div>
                      <div class="mapBlock__info-list-text" style="align-items: center;">
                        <span class="text text--departure">{{searchParams.departureName}}</span>
                        <span class="arrow">
                          <SvgIcon name="long-left-arrow"/>
                        </span>
                        <span class="text text--arrival">{{searchParams.arrivalName}}</span>
                      </div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--way">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="airplane"/>
                      </div>
                      <div class="mapBlock__info-list-text">{{searchParams.returnDate === null ? 'One way' : 'Round-trip'}}</div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--date">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="calendar"/>
                      </div>
                      <div class="mapBlock__info-list-text">
                        <span class="text text--desktop" v-if="!searchParams.returnDate">
                          {{months[new Date(searchParams.departureDate).getMonth()]}} {{new Date(searchParams.departureDate).getDate()}}, {{new Date(searchParams.departureDate).getFullYear()}}
                        </span>
                        <span class="text text--desktop" v-else>
                          {{months[new Date(searchParams.departureDate).getMonth()]}} {{new Date(searchParams.departureDate).getDate()}}, {{new Date(searchParams.departureDate).getFullYear()}}
                          - {{months[new Date(searchParams.returnDate).getMonth()]}} {{new Date(searchParams.returnDate).getDate()}}, {{new Date(searchParams.returnDate).getFullYear()}}
                        </span>
                        <span class="text text--mobile" v-if="!searchParams.returnDate">{{moment(searchParams.departureDate).format("MMM D, YYYY")}}</span>
                        <span class="text text--mobile" v-else>{{moment(searchParams.departureDate).format("MMM D, YYYY")}} - {{moment(searchParams.returnDate).format("MMM D, YYYY")}}</span>
                      </div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--count">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="user"/>
                      </div>
                      <div class="mapBlock__info-list-text">
                        <span>{{searchParams.pax}}</span>
                      </div>
                    </div>
                    <div class="mapBlock__info-list-item mapBlock__info-list-item--type">
                      <div class="mapBlock__info-list-icon">
                        <SvgIcon name="charter-flight"/>
                      </div>
                      <div v-if="searchParams.selectedBookingTypes === 2" class="mapBlock__info-list-text">Charter Aircraft</div>
                      <div v-else-if="searchParams.selectedBookingTypes === 4" class="mapBlock__info-list-text">Charter Seat</div>
                      <div v-else-if="searchParams.selectedBookingTypes === 6" class="mapBlock__info-list-text">Charter Aircraft, Charter Seat</div>
                      <div v-else-if="searchParams.selectedBookingTypes === 10" class="mapBlock__info-list-text">Charter Aircraft, Commercial Seat</div>
                      <div v-else-if="searchParams.selectedBookingTypes === 12" class="mapBlock__info-list-text">Charter Seat, Commercial Seat</div>
                      <div v-else-if="searchParams.selectedBookingTypes === 14" class="mapBlock__info-list-text">Charter Aircraft, Charter Seat, Commercial Seat</div>
                      <div v-else-if="!searchParams.selectedBookingTypes" class="mapBlock__info-list-text">Commercial Seat</div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <ResultSort :sort="sort"/> -->
                <div class="mapBlock__sort">
                  <div class="mapBlock__sort-title js-sortTitle">Sort by:</div>
                  <div class="mapBlock__sort-form">
                    <form
                      class="form form--sortSearchResults js-ajaxForm"
                      data-href="/"
                      data-action="sortSearchResults"
                      id="sortSearchResults">
                      <label class="form__label form__label--radio price">
                        <input
                          class="form__radio"
                          type="radio"
                          v-model="sort"
                          value="price up"/>
                        <span class="form__radio-box">Price</span>
                        <span class="form__radio-icon">
                          <SvgIcon name="long-up-arrow"/>
                        </span>
                      </label>
                      <label class="form__label form__label--radio price price--down">
                        <input
                          class="form__radio"
                          type="radio"
                          v-model="sort"
                          value="price down"
                        />
                        <span class="form__radio-box">Price</span>
                        <span class="form__radio-icon">
                          <SvgIcon name="long-down-arrow"/>
                        </span>
                      </label>
                      <label class="form__label form__label--radio js-moving">
                        <input
                          class="form__radio"
                          type="radio"
                          v-model="sort"
                          value="aircraft type"
                        />
                        <span class="form__radio-box">Aircraft type</span>
                      </label>
                      <label class="form__label form__label--radio js-moving">
                        <input
                          class="form__radio"
                          type="radio"
                          v-model="sort"
                          value="safety rating"
                        />
                        <span class="form__radio-box">Safety Rating</span>
                      </label>
                      <label class="form__label form__label--radio js-moving">
                        <input
                          class="form__radio"
                          type="radio"
                          v-model="sort"
                          value="trip time"/>
                        <span class="form__radio-box">Trip Time</span>
                      </label>
                      <!-- <label class="form__label form__label--radio js-moving">
                        <input
                          class="form__radio"
                          type="radio"
                          v-model="sort"
                          value="non-stop/number stops"
                        />
                        <span class="form__radio-box">Non-stop / Number Stops</span>
                      </label> -->
                      <div class="form__label form__label--mobile js-wrap-mobile"></div>
                    </form>
                  </div>
                </div>

              <form
                class="form form--filterSearchResults form--filterSearchResults--mobile js-filterSearchResults"
                :class="{'js-show' : mobileCommercialFilter}"
                @submit.prevent="submitHandler"
              >
                <div class="form__sliderForMobile">
                  <div class="form__sliderForMobile-wrap" id="frameAdult">
                    <div class="swiper-wrapper form__sliderForMobile-list">
                      <div
                        v-for="(item, index) of 99"
                        :key="index + 1"
                        class="swiper-slide form__sliderForMobile-listItem"
                        :data-id="index + 1"
                      >{{index + 1}}
                      </div>
                    </div>
                    <div class="form__sliderForMobile-listTitle">Adult</div>
                  </div>
                  <div class="form__sliderForMobile-wrap" id="frameChild">
                    <div class="swiper-wrapper form__sliderForMobile-list">
                      <div
                        v-for="(item, index) of 99"
                        :key="index + 1"
                        class="swiper-slide form__sliderForMobile-listItem"
                        :data-id="index + 1"
                      >{{index + 1}}
                      </div>
                    </div>
                    <div class="form__sliderForMobile-listTitle">Child</div>
                  </div>
                </div>
                <div class="form__labelGroup form__labelGroup--pet">
                  <div class="form__caption">Pet</div>
                  <label class="form__label form__label--radio">
                    <input class="form__radio" type="radio" v-model="pet" value="yes"/>
                    <span class="form__radio-icon"><SvgIcon name="checked"/></span>
                    <span class="form__radio-box">Yes</span>
                  </label>
                  <label class="form__label form__label--radio">
                    <input class="form__radio" type="radio" v-model="pet" value="no"/>
                    <span class="form__radio-icon"><SvgIcon name="nope"/></span>
                    <span class="form__radio-box">No</span>
                  </label>
                </div>
                <div class="form__labelGroup form__labelGroup--paxClass">
                  <label class="form__label form__label--pax form__label--checkbox">
                    <input class="form__checkbox" type="checkbox" v-model="paxClass" value="first"/>
                    <i class="form__checkbox-icon"></i>
                    <span class="form__checkbox-box">First class</span>
                  </label>
                  <label class="form__label form__label--pax form__label--checkbox">
                    <input class="form__checkbox" type="checkbox" v-model="paxClass" value="business"/>
                    <i class="form__checkbox-icon"></i>
                    <span class="form__checkbox-box">Business class</span>
                  </label>
                  <label class="form__label form__label--pax form__label--checkbox">
                    <input class="form__checkbox" type="checkbox" v-model="paxClass" value="economy"/>
                    <i class="form__checkbox-icon"></i>
                    <span class="form__checkbox-box">Economy class</span>
                  </label>
                </div>
                <div class="form__label form__label--button">
                  <button class="form__button"><span class="text">Refine search</span></button>
                </div>
              </form>
            </div>
          </div>
          <div class="mapBlock__header-bottom js-tabNav-wrap">
            <div class="mapBlock__tab-nav">
              <div
                v-if="searchParams.selectedBookingTypes === 2 ||
                  searchParams.selectedBookingTypes === 6 ||
                  searchParams.selectedBookingTypes === 10 ||
                  searchParams.selectedBookingTypes === 14"
                class="mapBlock__tab-nav-item js-tabNavItem"
                data-id="charterAircraft"
                @click="tabActivate"
              >
                <span class="text">Charter aircraft</span>
              </div>
              <div
                v-if="searchParams.selectedBookingTypes === 2 ||
                  searchParams.selectedBookingTypes === 6 ||
                  searchParams.selectedBookingTypes === 10 ||
                  searchParams.selectedBookingTypes === 14"
                class="mapBlock__tab-nav-item js-tabNavItem"
                data-id="charterFlights"
                @click="tabActivate"
              >
                <span class="text">Charter flights</span>
              </div>
              <div
                v-if="searchParams.selectedBookingTypes === 4 ||
                  searchParams.selectedBookingTypes === 6 ||
                  searchParams.selectedBookingTypes === 12 ||
                  searchParams.selectedBookingTypes === 14"
                class="mapBlock__tab-nav-item js-tabNavItem"
                data-id="charterAircraftSeats"
                @click="tabActivate"
              >
                <span class="text">Charter aircraft spaces</span>
              </div>
              <div
                v-if="searchParams.selectedBookingTypes === 4 ||
                  searchParams.selectedBookingTypes === 6 ||
                  searchParams.selectedBookingTypes === 12 ||
                  searchParams.selectedBookingTypes === 14"
                class="mapBlock__tab-nav-item js-tabNavItem"
                data-id="charterFlightsSeats"
                @click="tabActivate"
              >
                <span class="text">Charter flight spaces</span>
              </div>
              <!-- <div
                v-if="searchParams.selectedBookingTypes === 10 ||
                  searchParams.selectedBookingTypes === 12 ||
                  searchParams.selectedBookingTypes === 14 ||
                  !searchParams.selectedBookingTypes"
                class="mapBlock__tab-nav-item js-tabNavItem"
                data-id="commercialSeats"
                @click="tabActivate"
              >
                <span class="text">Commercial seats</span>
              </div> -->
              <div class="form__label form__label--button" style="margin-bottom: 0">
                <router-link
                  id="manual-request"
                  class="mapBlock__results-list-item-button mapBlock__results-list-item-button--green bookBtn"
                  to="manually-request"
                >
                  Manual Req.
                </router-link>
              </div>
            </div>
<!--            <router-link to="manually-request" class="mapBlock__link mapBlock__link&#45;&#45;desktop">-->
<!--              <span class="icon"></span>-->
<!--              <span class="text">Manual Request</span>-->
<!--            </router-link>-->
          </div>
        </div>
        <div class="mapBlock__content">
          <router-link class="mapBlock__sidebar-title mapBlock__sidebar-title--search-results" to="map-search">
            <div class="mapBlock__sidebar-title-inner">
              <span class="text">Map</span>
              <span class="icon">
                  <SvgIcon name="down-arrow"/>
                </span>
            </div>
          </router-link>
          <div class="mapBlock__sidebar-content mapBlock__sidebar-content--search-results">
            <div class="mapBlock__tab-content">
              <div
                v-if="searchParams.selectedBookingTypes === 2 ||
                  searchParams.selectedBookingTypes === 6 ||
                  searchParams.selectedBookingTypes === 10 ||
                  searchParams.selectedBookingTypes === 14"
                class="mapBlock__tab-content-item js-tabContentItem"
              >
                <div class="mapBlock__tab-content-inner">
                  <ResultsList :type="'book'" :list="listAircrafts"/>
                </div>
              </div>
              <div
                v-if="searchParams.selectedBookingTypes === 2 ||
                  searchParams.selectedBookingTypes === 6 ||
                  searchParams.selectedBookingTypes === 10 ||
                  searchParams.selectedBookingTypes === 14"
                class="mapBlock__tab-content-item js-tabContentItem"
              >
                <div class="mapBlock__tab-content-inner">
                  <ResultsList :type="'book'" :list="listFlights" :isFlights="true" />
                </div>
<!--                <div class="mapBlock__tab-content-inner">-->
<!--                  <div class="mapBlock__results-form">-->
<!--                    <form-->
<!--                      class="form form&#45;&#45;filterResults"-->
<!--                      data-href=""-->
<!--                      data-action="filterLegs">-->
<!--                      <label class="form__label form__label&#45;&#45;radio">-->
<!--                        <input class="form__radio" type="radio" v-model="type" value="leg one"/>-->
<!--                        <span class="form__radio-box">Leg one</span>-->
<!--                      </label>-->
<!--                      <label class="form__label form__label&#45;&#45;radio">-->
<!--                        <input class="form__radio" type="radio" v-model="type" value="leg two"/>-->
<!--                        <span class="form__radio-box">Leg two</span>-->
<!--                      </label>-->
<!--                      <label class="form__label form__label&#45;&#45;radio">-->
<!--                        <input class="form__radio" type="radio" v-model="type" value="leg three"/>-->
<!--                        <span class="form__radio-box">Leg three</span>-->
<!--                      </label>-->
<!--                    </form>-->
<!--                  </div>-->
<!--                  <ResultsList :list="list"/>-->
<!--                </div>-->
              </div>
              <div
                v-if="searchParams.selectedBookingTypes === 4 ||
                  searchParams.selectedBookingTypes === 6 ||
                  searchParams.selectedBookingTypes === 12 ||
                  searchParams.selectedBookingTypes === 14"
                class="mapBlock__tab-content-item js-tabContentItem"
              >
                <div class="mapBlock__tab-content-inner">
                  <ResultsList :type="'reserve'" :list="listAircraftSpaces"/>
                </div>
              </div>
              <div
                v-if="searchParams.selectedBookingTypes === 4 ||
                  searchParams.selectedBookingTypes === 6 ||
                  searchParams.selectedBookingTypes === 12 ||
                  searchParams.selectedBookingTypes === 14"
                class="mapBlock__tab-content-item js-tabContentItem"
              >
                <div class="mapBlock__tab-content-inner">
                  <ResultsList :type="'reserve'" :list="listFlightSpaces"/>
                </div>
<!--                <div class="mapBlock__tab-content-inner">-->
<!--                  <div class="mapBlock__results-form">-->
<!--                    <form-->
<!--                      class="form form&#45;&#45;filterResults"-->
<!--                      data-href=""-->
<!--                      data-action="filterRoute"-->
<!--                    >-->
<!--                      <label class="form__label form__label&#45;&#45;radio">-->
<!--                        <input class="form__radio" type="radio" v-model="typeFlightsSeats" value="outbound"/>-->
<!--                        <span class="form__radio-box">Outbound</span>-->
<!--                      </label>-->
<!--                      <label class="form__label form__label&#45;&#45;radio">-->
<!--                        <input class="form__radio" type="radio" v-model="typeFlightsSeats" value="inbound"/>-->
<!--                        <span class="form__radio-box">Inbound</span>-->
<!--                      </label>-->
<!--                    </form>-->
<!--                  </div>-->
<!--                  <ResultsList :list="list"/>-->
<!--                </div>-->
              </div>
              <div
                v-if="searchParams.selectedBookingTypes === 10 ||
                  searchParams.selectedBookingTypes === 12 ||
                  searchParams.selectedBookingTypes === 14 ||
                  !searchParams.selectedBookingTypes"
                class="mapBlock__tab-content-item js-tabContentItem"
              >
                <div class="mapBlock__tab-content-inner">
                  <div class="mapBlock__results-form">
                    <form
                      class="form form--filterSearchResults js-filterSearchResults"
                      data-href=""
                      data-action="filterSearchResults"
                      id="filterSearchResults"
                      @submit.prevent="submitHandler"
                    >
                      <div class="form__title">Enter clarifications:</div>
                      <label class="form__label form__label--number js-counter">
                        <div class="form__field-wrapper">
                          <div class="form__caption">Adult</div>
                          <input
                            class="form__field js-counter-input js-conter-adult"
                            type="number"
                            v-model="adult"
                            @blur="input('adult')"
                            :disabled="loading"
                          />
                          <div class="form__arrow">
                            <div class="form__arrow-plus js-counter-plus" @click="plus('adult')">
                              <SvgIcon name="up-arrow"/>
                            </div>
                            <div class="form__arrow-minus js-counter-minus" @click="minus('adult')">
                              <SvgIcon name="down-arrow"/>
                            </div>
                          </div>
                        </div>
                      </label>
                      <label class="form__label form__label--number js-counter">
                        <div class="form__field-wrapper">
                          <div class="form__caption">Children</div>
                          <input
                            class="form__field js-counter-input js-conter-child"
                            type="number"
                            v-model="children"
                            @blur="input('children')"
                            :disabled="loading"
                          />
                          <div class="form__arrow">
                            <div class="form__arrow-plus js-counter-plus" @click="plus('children')">
                              <SvgIcon name="up-arrow"/>
                            </div>
                            <div class="form__arrow-minus js-counter-minus" @click="minus('children')">
                              <SvgIcon name="down-arrow"/>
                            </div>
                          </div>
                        </div>
                      </label>
                      <div class="form__labelGroup form__labelGroup--pet">
                        <div class="form__caption">Pet</div>
                        <label class="form__label form__label--radio">
                          <input class="form__radio" type="radio" v-model="pet" value="yes"/>
                          <span class="form__radio-icon"><SvgIcon name="checked"/></span>
                          <span class="form__radio-box">Yes</span>
                        </label>
                        <label class="form__label form__label--radio">
                          <input class="form__radio" type="radio" v-model="pet" value="no"/>
                          <span class="form__radio-icon"><SvgIcon name="nope"/></span>
                          <span class="form__radio-box">No</span>
                        </label>
                      </div>
                      <div class="form__labelGroup form__labelGroup--paxClass">
                        <label class="form__label form__label--pax form__label--checkbox">
                          <input class="form__checkbox" type="checkbox" v-model="paxClass" value="first"/>
                          <i class="form__checkbox-icon"></i>
                          <span class="form__checkbox-box">First class</span>
                        </label>
                        <label class="form__label form__label--pax form__label--checkbox">
                          <input class="form__checkbox" type="checkbox" v-model="paxClass" value="business"/>
                          <i class="form__checkbox-icon"></i>
                          <span class="form__checkbox-box">Business class</span>
                        </label>
                        <label class="form__label form__label--pax form__label--checkbox">
                          <input class="form__checkbox" type="checkbox" v-model="paxClass" value="economy"/>
                          <i class="form__checkbox-icon"></i>
                          <span class="form__checkbox-box">Economy class</span>
                        </label>
                      </div>
                      <div class="form__label form__label--button">
                        <button class="form__button"><span class="text">Refine search</span></button>
                      </div>
                    </form>
                  </div>
<!--                  <ResultsList :type="'book'" :list="list"/>-->
                </div>
              </div>
            </div>
            <router-link to="/manually-request" class="mapBlock__link mapBlock__link--mobile">
              <span class="icon"></span>
              <span class="text">Manual Request</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <BookingConfirmPopup />
    <TimeRequestPopup />
    <BookRoutesPopup />
    <AlertsPopup />
    <!-- -- -->
    <!-- <SearchResultsNotFoundPopUp @goToManualRequest="onNotFoundClick" /> -->
  </div>
</template>

<script>
  import moment from 'moment';
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  // import ResultSort from '@/components/ResultSort/ResultSort'
  import { Tabs } from '@/js/plugins/tabs'
  import ResultsList from '@/views/main/SearchResults/ResultsList'
  import BookingConfirmPopup from '@/components/common/modals/BookConfirmPopup'
  import TimeRequestPopup from '@/components/common/modals/TimeRequestPopup'
  import { Counter } from '@/js/plugins/counter'
  import BookRoutesPopup from '@/components/common/modals/BookRoutesPopup'
  import AlertsPopup from '@/components/common/modals/AlertsPopup';
  import {months} from '@/js/utils/date'
  import './SearchResults.styl'
  import Swiper from 'swiper'
  // --
  // import SearchResultsNotFoundPopUp from '@/components/common/modals/SearchResultsNotFoundPopUp'

  const adultCounter = new Counter({
    min: 1,
    max: 99,
  })
  const childrenCounter = new Counter({
    min: 1,
    max: 99,
  })

  export default {
    name: 'SearchResults',
    data: () => ({
      tabs: new Tabs('.js-tabNavItem', '.js-tabContentItem'),
      type: 'leg one',
      typeFlightsSeats: 'outbound',
      adult: 2,
      children: 2,
      pet: 'no',
      loading: false,
      paxClass: [],
      progressBidUpdate: false,
      mobileCommercialFilter: false,
      mobileAdultCounter: null,
      mobileChildCounter: null,
      months: months,
      sort: 'price up',
      intervalId: null,
    }),
    computed: {
      listAircrafts () {
        const searchResults = this.$store.state.search.searchResults.aircraftAvailabilities;
        return this.sortAircraftList(searchResults);
      },

      listFlights () {
        const searchResults = this.$store.state.search.searchResults.emptyLegs;
        return this.sortAircraftList(searchResults);
      },

      listAircraftSpaces () {
        const searchResults = this.$store.state.search.searchResultsCharterFlights.aircraftAvailabilities;
        return this.sortAircraftList(searchResults);
      },

      listFlightSpaces () {
        const searchResults = this.$store.state.search.searchResultsCharterFlights.emptyLegs;
        return this.sortAircraftList(searchResults);
      },

      searchParams () {
        const searchResults = this.$store.state.search.searchRequest;
        return this.sortAircraftList(searchResults);
      },
    },

    async mounted () {
      const aircrafts = this.$store.state.search.searchResults.aircraftAvailabilities;
      const flights = this.$store.state.search.searchResults.emptyLegs;
      const aircraftSpaces = this.$store.state.search.searchResultsCharterFlights.aircraftAvailabilities;
      const flightSpaces = this.$store.state.search.searchResultsCharterFlights.emptyLegs;
      const bookingType = this.$store.state.search.searchRequest.selectedBookingTypes;

      if (!aircrafts) {
        return this.$router.push('/map-search')
      }

      const filteredAircraftsByBid = aircrafts.reduce((result, { currentBidValue, flightId }) => {
        if (currentBidValue > 0) {
          result.push(flightId);
        }
        return result;
      }, []);

      if (filteredAircraftsByBid.length > 0) {
        this.intervalId = setInterval(() => this.updateBidArray(aircrafts, filteredAircraftsByBid), 1000);
      }

      if (flights.length > 0) {
        this.tabs.addActiveForMouted(1)
      } else if (aircrafts.length > 0) {
        this.tabs.addActiveForMouted()
      } else if (aircraftSpaces.length > 0) {
        bookingType === 4 ? this.tabs.addActiveForMouted() : this.tabs.addActiveForMouted(2)
      } else if (flightSpaces.length > 0) {
        bookingType === 4 ? this.tabs.addActiveForMouted(1) : this.tabs.addActiveForMouted(3)
      } else if (
        aircrafts.length === 0 &&
        flights.length === 0 &&
        aircraftSpaces.length === 0 &&
        flightSpaces.length === 0
      ) {
        if (bookingType === 4) {
          this.$modal.show('AlertsPopup', {
              title: 'Charter Flight Spaces Not Found',
              text: 'We do not currently have any charter aircraft spaces that fits your criteria listed in our system.  We will, however, be back to you with flight options within 24 hours, by email and by message, once we perform a manual search.  Click "Request Flight" below to initiate this search process.',
          });
        } else {
          this.$modal.show('AlertsPopup', {
            title: 'No Charter Aircraft Found',
            text: 'We do not currently have any charter aircraft that fits your criteria listed in our system.  We will, however, be back to you with flight options within 24 hours, by email and by message, once we perform a manual search.  Click "Request Flight" below to initiate this search process.',
          });
        }
      }

      this.$root.fields.changeChecked('.form__checkbox')
      this.$root.fields.changeRadio('.form__radio')

      if (document.querySelector('html').classList.contains('d-mobile')) {
        const resultsTabs = document.querySelector('.mapBlock__header-bottom')
        const tabsContainer = document.querySelector('.mapBlock__info-right')
        tabsContainer.appendChild(resultsTabs)
      }
    },
    unmounted () {
      clearInterval(this.interval)
    },
    beforeDestroy () {
      if (this.intervalId) {
        clearInterval(this.intervalId);
      }
    },
    methods: {
      moment,
      async updateBidArray (aircrafts, filteredAircraftsByBid) {
        if (this.progressBidUpdate) {
          return;
        }

        this.progressBidUpdate = true;

        const updatedBidArray = await this.$store.dispatch('getBidValues', filteredAircraftsByBid)
        const updatedAircrafts = aircrafts.map(ac => (
          {
            ...ac,
            currentBidValue: updatedBidArray.find(bidObj => bidObj.flightId === ac.flightId)?.bidValue || ac.currentBidValue,
          }
        ))

        await this.$store.commit('setSearchResults', {
          aircraftAvailabilities: updatedAircrafts,
          emptyLegs: this.$store.state.search.searchResults.emptyLegs,
        })

        this.progressBidUpdate = false;
      },
      tabActivate (event) {
        this.tabs.addActiveForClick(event.currentTarget)

        if (document.querySelector('html').classList.contains('d-mobile')) {
          if (event.currentTarget.getAttribute('data-id') === 'commercialSeats') {
            this.mobileCommercialFilter = true

            setTimeout(() => {
              this.mobileAdultCounter = new Swiper('#frameAdult', {
                slidesPerView: 1,
                initialSlide: this.adult - 1,
                direction: 'vertical',
              })

              this.mobileChildCounter = new Swiper('#frameChild', {
                slidesPerView: 1,
                initialSlide: this.children - 1,
                direction: 'vertical',
              })

              this.mobileAdultCounter.on('slideChangeTransitionEnd', (event) => {
                const value = document.getElementById('frameAdult')
                  .querySelector('.form__sliderForMobile-listItem.swiper-slide-active')
                this.adult = +value.getAttribute('data-id')
              })

              this.mobileChildCounter.on('slideChangeTransitionEnd', (event) => {
                const value = document.getElementById('frameChild')
                  .querySelector('.form__sliderForMobile-listItem.swiper-slide-active')
                this.children = +value.getAttribute('data-id')
              })
            }, 0)
          } else {
            this.mobileCommercialFilter = false
          }
        }

        if (event.currentTarget.getAttribute('data-id') === 'charterAircraft') {
          if (!this.listAircrafts.length) {
            this.$modal.show('AlertsPopup', {
              title: 'No Charter Aircraft Found',
              text: 'We do not currently have any charter aircrafts that fit your criteria listed in our system.  We will, however, be back to you with flight options within 24 hours, by email and by message, once we perform a manual search.  Click "Request Flight" below to initiate this search process.',
            })
          }
        } else if (event.currentTarget.getAttribute('data-id') === 'charterFlights') {
          if (!this.listFlights.length) {
            this.$modal.show('AlertsPopup', {
              title: 'No Charter Flights Found',
              text: 'We do not currently have any charter flights that fit your criteria listed in our system.  We will, however, be back to you with flight options within 24 hours, by email and by message, once we perform a manual search.  Click "Request Flight" below to initiate this search process.',
            })
          }
        } else if (event.currentTarget.getAttribute('data-id') === 'charterAircraftSeats') {
          if (!this.listAircraftSpaces.length) {
            this.$modal.show('AlertsPopup', {
              title: 'No Charter Aircraft Seat Found',
              text: 'We do not currently have any charter aircraft seats that fit your criteria listed in our system.  We will, however, be back to you with flight options within 24 hours, by email and by message, once we perform a manual search.  Click "Request Flight" below to initiate this search process.',
            })
          }
        } else if (event.currentTarget.getAttribute('data-id') === 'charterFlightsSeats') {
          if (!this.listFlightSpaces.length) {
            this.$modal.show('AlertsPopup', {
              title: 'No Charter Flight Seat Found',
              text: 'We do not currently have any charter flight seats that fit your criteria listed in our system.  We will, however, be back to you with flight options within 24 hours, by email and by message, once we perform a manual search.  Click "Request Flight" below to initiate this search process.',
            })
          }
        }
      },
      minus (type) {
        switch (type) {
          case 'adult':
            this.adult = adultCounter.callMinus(this.adult)
            break
          case 'children':
            this.children = childrenCounter.callMinus(this.children)
            break
          default:
            return false
        }
      },
      plus (type) {
        switch (type) {
          case 'adult':
            this.adult = adultCounter.callPlus(this.adult)
            break
          case 'children':
            this.children = childrenCounter.callPlus(this.children)
            break
          default:
            return false
        }
      },
      input (type) {
        switch (type) {
          case 'adult':
            this.adult = adultCounter.callInput(this.adult)
            break
          case 'children':
            this.children = childrenCounter.callInput(this.children)
            break
          default:
            return false
        }
      },
      submitHandler () {
        const data = {
          adult: this.adult,
          children: this.children,
          pet: this.pet,
          paxClass: this.paxClass,
        }

        console.log(data)
      },
      onNotFoundClick () {
        this.$modal.hide('not-found')

        this.$router.push('/manually-request')
      },
      sortAircraftList (searchResults) {
        const aircraftTypes = ['Turboprop', 'Very Light Jet', 'Light Jet', 'Midsize Jet', 'Super Midsize Jet', 'Heavy Jet', 'Ultra-Long-Range', 'Executive Airliner', 'Helicopter'];
        const safetyRatings = ['Platinum', 'Gold'];

        if (searchResults.length > 1) {
          if (this.sort === 'price down') {
            // type === 'reserve' ? searchResults.sort((a, b) => (b.exclusiveTotalPrice / b.aircraftPax) - (a.exclusiveTotalPrice / a.aircraftPax)) : searchResults.sort((a, b) => b.exclusiveTotalPrice - a.exclusiveTotalPrice)
            return searchResults.sort((a, b) => b.exclusiveTotalPrice - a.exclusiveTotalPrice);
          } else if (this.sort === 'price up') {
            // type === 'reserve' ? searchResults.sort((a, b) => (a.exclusiveTotalPrice / a.aircraftPax) - (b.exclusiveTotalPrice / b.aircraftPax)) : searchResults.sort((a, b) => a.exclusiveTotalPrice - b.exclusiveTotalPrice)
            return searchResults.sort((a, b) => a.exclusiveTotalPrice - b.exclusiveTotalPrice);
          } else if (this.sort === 'aircraft type') {
            return searchResults.sort((a, b) => {
              if (aircraftTypes.indexOf(a.aircraftType) > -1 && aircraftTypes.indexOf(b.aircraftType) > -1) return aircraftTypes.indexOf(a.aircraftType) - aircraftTypes.indexOf(b.aircraftType);
              else return aircraftTypes.indexOf(b.aircraftType) - aircraftTypes.indexOf(a.aircraftType);
            });
          } else if (this.sort === 'safety rating') {
            return searchResults.sort((a, b) => {
              if (safetyRatings.indexOf(a.aircraftArgusSafetyRating) > -1 && safetyRatings.indexOf(b.aircraftArgusSafetyRating) > -1) return safetyRatings.indexOf(a.aircraftArgusSafetyRating) - safetyRatings.indexOf(b.aircraftArgusSafetyRating);
              else return safetyRatings.indexOf(b.aircraftArgusSafetyRating) - safetyRatings.indexOf(a.aircraftArgusSafetyRating);
            });
          } else if (this.sort === 'trip time') {
            return searchResults.sort((a, b) => a.flightDurationHours * 60 + a.flightDurationMinutes - b.flightDurationHours * 60 - b.flightDurationMinutes);
          } else if (this.sort === 'non-stop/number stops') {

          }
        }

        return searchResults;
      },
    },
    components: {
      SvgIcon,
      // ResultSort,
      ResultsList,
      BookingConfirmPopup,
      TimeRequestPopup,
      BookRoutesPopup,
      AlertsPopup,
      // SearchResultsNotFoundPopUp,
    },
  }
</script>
