<template>
  <modal name="timeRequestPopup" transition="pop-out" @opened="beforePopupOpen">
    <div class="timeRequestPopup" id="timeRequestPopup">
      <div class="timeRequestPopup__inner">
        <div class="timeRequestPopup__title">Time request</div>
        <div class="timeRequestPopup__route">
          <div class="timeRequestPopup__route-item">
            <div class="timeRequestPopup__route-title">
              <span class="icon">
                <SvgIcon name="departures"/>
              </span>
              <span class="text">Outbound:</span>
            </div>
            <div class="timeRequestPopup__route-text">Teterboro airport (KTEB)</div>
          </div>
          <div class="timeRequestPopup__route-item">
            <div class="timeRequestPopup__route-title">
              <span class="icon">
                <SvgIcon name="arrival"/>
              </span>
              <span class="text">Inbound:</span>
            </div>
            <div class="timeRequestPopup__route-text">Orlando international airport (MCO)</div>
          </div>
        </div>
        <div class="timeRequestPopup__form">
          <form
            class="form form--timeRequest js-ajaxForm"
            data-href="/json/test.json"
            data-action="timeRequest"
            id="timeRequestForm"
            @submit.prevent="submitHandler"
            :class="{'loading' : loading}"
          >
            <div class="form__time">
              <div class="form__labelGroup form__labelGroup--counter">
                <label class="form__label form__label--time js-counter">
                  <div class="form__caption">Hours</div>
                  <div class="form__field-wrapper">
                    <div class="form__arrow-plus js-counter-plus" @click="plus('hours')">
                      <SvgIcon name="up-arrow"/>
                    </div>
                    <input
                      class="form__field js-counter-input"
                      type="number"
                      min="1"
                      max="12"
                      v-model="hours"
                      required="required"
                      @blur="input('hours')"
                      :disabled="loading"
                    />
                    <div class="form__arrow-minus js-counter-minus" @click="minus('hours')">
                      <SvgIcon name="down-arrow"/>
                    </div>
                  </div>
                </label>
                <label class="form__label form__label--time js-counter">
                  <div class="form__caption">Minutes</div>
                  <div class="form__field-wrapper">
                    <div class="form__arrow-plus js-counter-plus" @click="plus('minutes')">
                      <SvgIcon name="up-arrow"/>
                    </div>
                    <input
                      class="form__field js-counter-input"
                      type="number"
                      min="0"
                      max="59"
                      v-model="minutes"
                      required="required"
                      @blur="input('minutes')"
                      :disabled="loading"
                    />
                    <div class="form__arrow-minus js-counter-minus" @click="minus('minutes')">
                      <SvgIcon name="down-arrow"/>
                    </div>
                  </div>
                </label>
              </div>
              <div class="form__labelGroup form__labelGroup--radio">
                <label class="form__label form__label--radio" :class="{'checked' : time === 'AM'}">
                  <input class="form__radio" type="radio" v-model="time" value="AM" :disabled="loading"/>
                  <span class="form__radio-box">AM</span>
                </label>
                <label class="form__label form__label--radio" :class="{'checked' : time === 'PM'}">
                  <input class="form__radio" type="radio" v-model="time" value="PM" :disabled="loading"/>
                  <span class="form__radio-box">PM</span>
                </label>
              </div>
              <div class="form__info">Enter the requested time</div>
            </div>
            <label class="form__label form__label--select">
              <div class="form__caption">Select time zone</div>
              <div class="form__field-wrapper">
                <div class="selectContainer"></div>
                <a-select
                  class="form__select"
                  label-in-value
                  :default-value="{ key: timeZone }"
                  :getPopupContainer="selectContainer"
                  @change="selectChange"
                  :disabled="loading"
                >
                  <a-select-option :value="'GMT+0:00'">GMT Greenwich Mean</a-select-option>
                  <a-select-option :value="'GMT+1:00'">ECT European Central (GMT+1:00)</a-select-option>
                  <a-select-option :value="'GMT+2:00'">EET Eastern European (GMT+2:00)</a-select-option>
                  <a-select-option :value="'GMT+2:30'">ART (Arabic) Egypt (GMT+2:00)</a-select-option>
                  <a-select-option :value="'GMT+3:00'">EAT Eastern African (GMT+3:00)</a-select-option>
                  <a-select-option :value="'GMT+3:30'">MET Middle East (GMT+3:30)</a-select-option>
                  <a-select-option :value="'GMT+4:00'">NET Near East Time (GMT+4:00)</a-select-option>
                  <a-select-option :value="'GMT+5:00'">PLT Pakistan Lahore(GMT+5:00)</a-select-option>
                  <a-select-option :value="'GMT+5:30'">IST India (GMT+5:30)</a-select-option>
                  <a-select-option :value="'GMT+6:00'">BST Bangladesh (GMT+6:00)</a-select-option>
                  <a-select-option :value="'GMT+7:00'">VST Vietnam (GMT+7:00)</a-select-option>
                  <a-select-option :value="'GMT+8:00'">CTT China (GMT+8:00)</a-select-option>
                  <a-select-option :value="'GMT+9:00'">JST Japan (GMT+9:00)</a-select-option>
                  <a-select-option :value="'GMT+9:30'">ACT Australia Central (GMT+9:30)</a-select-option>
                  <a-select-option :value="'GMT+10:00'">AET Australia Eastern (GMT+10:00)</a-select-option>
                  <a-select-option :value="'GMT+11:00'">SST Solomon (GMT+11:00)</a-select-option>
                  <a-select-option :value="'GMT+12:00'">NST New Zealand(GMT+12:00)</a-select-option>
                  <a-select-option :value="'GMT-11:00'">MIT Midway Islands (GMT-11:00)</a-select-option>
                  <a-select-option :value="'GMT-10:00'">HST Hawaii (GMT-10:00)</a-select-option>
                  <a-select-option :value="'GMT-9:00'">AST Alaska (GMT-9:00)</a-select-option>
                  <a-select-option :value="'GMT-8:00'">PST Pacific (GMT-8:00)</a-select-option>
                  <a-select-option :value="'GMT-7:00'">PNT Phoenix (GMT-7:00)</a-select-option>
                  <a-select-option :value="'GMT-6:00'">CST Central (GMT-6:00)</a-select-option>
                  <a-select-option :value="'GMT-5:00'">EST Eastern (GMT-5:00)</a-select-option>
                  <a-select-option :value="'GMT-4:00'">PRT Puerto Rico and US Virgin Islands (GMT-4:00)
                  </a-select-option>
                  <a-select-option :value="'GMT-3:30'">CNT Canada Newfoundland (GMT-3:30)</a-select-option>
                  <a-select-option :value="'GMT-3:00'">BET Brazil Eastern, Argentina (GMT-3:00)</a-select-option>
                  <a-select-option :value="'GMT-1:00'">CAT Central African (GMT-1:00)</a-select-option>
                </a-select>
              </div>
            </label>
            <div class="form__label form__label--button">
              <Spinner/>
              <button class="form__button" :disabled="loading"><span class="text">Confirm time</span></button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  import { Counter } from '@/js/plugins/counter'
  import './TimeRequestPopup.styl'

  const counterHour = new Counter({
    min: 1,
    max: 12,
  })
  const counterMinutes = new Counter({
    min: 0,
    max: 59,
  })

  export default {
    name: 'TimeRequestPopup',
    data: () => ({
      hours: 7,
      minutes: 30,
      time: 'AM',
      timeZone: 'GMT-7:00',
      loading: false,
    }),
    methods: {
      minus (type) {
        switch (type) {
          case 'hours':
            this.hours = counterHour.callMinus(this.hours)
            break
          case 'minutes':
            this.minutes = counterMinutes.callMunutesMinus(this.minutes)
            break
          default:
            return false
        }
      },
      plus (type) {
        switch (type) {
          case 'hours':
            this.hours = counterHour.callPlus(this.hours)
            break
          case 'minutes':
            this.minutes = counterMinutes.callMunutesPlus(this.minutes)
            break
          default:
            return false
        }
      },
      input (type) {
        switch (type) {
          case 'hours':
            this.hours = counterHour.callInput(this.hours)
            break
          case 'minutes':
            this.minutes = counterMinutes.callMunutesInput(this.minutes)
            break
          default:
            return false
        }
      },
      selectContainer () {
        return document.querySelector('.selectContainer')
      },
      beforePopupOpen () {
        this.$root.fields.changeRadio('.form__radio')
      },
      selectChange (value) {
        this.timeZone = value.key
      },
      submitHandler () {
        this.loading = true

        const data = {
          hours: +this.hours,
          minutes: +this.minutes,
          time: this.time,
          timeZone: this.timeZone,
        }

        console.log(data)
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
